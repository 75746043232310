import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from '../../services';
import { OverviewService } from './../../../subscription/overview/overview.service';

@Component({
  selector: 'app-cancel-subscription-banner',
  templateUrl: './cancel-subscription-banner.component.html',
  styleUrls: ['./cancel-subscription-banner.component.scss'],
  providers: [OverviewService],
})
export class CancelSubscriptionBannerComponent implements OnInit {
  constructor(
    public authenticationService: AuthenticationService,
    public overviewService: OverviewService,
    public dialog: MatDialog
  ) {}
  loading = false;
  get currentUser() {
    return this.authenticationService.user;
  }

  revertCancellation() {
    const message = `Are you sure you want to revert the cancellation?`;
    const dialogData = new ConfirmDialogModel('Revert Cancellation', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.loading = true;
        this.overviewService
          .revertSubscription(this.currentUser?.subscriptions?.[0]?.id)
          .subscribe(
            (r) => {
              this.loading = false;
              this.authenticationService.user.subscriptions[0].canceledDate =
                null;
            },
            (error) => {
              console.error('Error canceling subscription:', error);
              this.loading = false;
            }
          );
      }
    });
  }

  ngOnInit(): void {}
}
