import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@environments/environment';
import { AppModule } from './app/app.module';
import posthog from 'posthog-js';

if (environment.production) {
  enableProdMode();
  posthog.init(environment.postHogKey, {
    api_host: environment.postHogHost,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
