import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClientXsrfModule,
} from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ErrorInterceptor } from './_shared/helpers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './_shared/material.module';
import { WINDOW_PROVIDERS } from './_shared/window-token';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '@environments/environment';
import { MainLayoutComponent } from './_layouts/main-layout/main-layout.component';
import { SharedModule } from './_shared/shared.module';
import { AuthenticationService } from './_shared/services';
import { WithCredentialsInterceptor } from './_shared/helpers/with-credentials.interceptor';
import { HttpXSRFInterceptor } from './_shared/helpers/csrf.interceptor';
import { PixelModule } from 'ngx-pixel';

export function init_app(service: AuthenticationService) {
  return () => service.getUser();
}

@NgModule({
  imports: [
    BrowserModule,
    ...(environment.production
      ? [PixelModule.forRoot({ enabled: true, pixelId: '317512812871957' })]
      : []),
    SimpleNotificationsModule.forRoot(),
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    // HttpClientXsrfModule.withOptions({
    //   cookieName: 'XSRF-TOKEN',
    //   headerName: 'X-CSRF-TOKEN',
    // }),
    NgxStripeModule.forRoot(environment.stripePk),
  ],
  declarations: [AppComponent, MainLayoutComponent],
  providers: [
    WINDOW_PROVIDERS,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpXSRFInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AuthenticationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
