import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class PlanService {
  constructor(private http: HttpClient) {}

  getFeatures(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/features`);
  }

  getAll(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/packages`);
  }

  getAllSubscriptions() {
    return this.http.get<any>(
      `${environment.apiUrl}/subscriptions/my-subscriptions`
    );
  }

  getPricingPlans(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/packages/pricing`);
  }

  getDiscounts(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/packages/discounts`);
  }

  createCheckoutSession(
    priceId,
    packId,
    type,
    subscriptionId,
    isRecurrent,
    couponId
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/subscriptions/create-checkout-session`,
      {
        priceId,
        packId,
        type,
        subscriptionId,
        isRecurrent,
        couponId,
      }
    );
  }

  createUpgradeCheckoutSession(
    priceId,
    packId,
    type,
    subscriptionId,
    isRecurrent,
    couponId
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/subscriptions/upgrade-subscription`,
      {
        priceId,
        packId,
        type,
        subscriptionId,
        isRecurrent,
        couponId,
      }
    );
  }

  cancelSubscription(subscriptionId): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}/subscriptions/cancel-my-subscription/${subscriptionId}`,
      {
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  getSubscriptions() {
    return this.http.get<any>(
      `${environment.apiUrl}/subscriptions/my-subscriptions`
    );
  }

  upgradeVersion() {
    return this.http.post<any>(
      `${environment.apiUrl}/subscriptions/create-new-subscription`,
      {}
    );
  }

  applyCoupon(coupon) {
    return this.http.get<any>(
      `${environment.apiUrl}/stripe/validate/${coupon}`,
      {}
    );
  }

  applyTokenCoupon(body) {
    return this.http.post<any>(`${environment.apiUrl}/coins/apply`, body);
  }

  activateCode(code): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/payments/activate-by-code/${code}`,
      {}
    );
  }

  getTokens(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/packages/token-packages`);
  }

  buyTokens(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/coins/buy-tokens`,
      payload
    );
  }
}
