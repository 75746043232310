<div
  class="banner"
  *ngIf="
    currentUser?.user_version === 'V1.0' &&
    currentUser?.v1_plan !== 'Free Plan' &&
    currentUser?.subscriptions?.length < 2 &&
    currentUser?.emailActivated &&
    currentUser?.user_activated === 'Y' &&
    !isActivationBannerHidden
  "
>
  <img src="../../assets/icons/upgrade-alert.svg" alt="" />
  <div>
    <span class="upgrade-label">Upgrade Alert!</span> As an existing customer,
    you're qualified for an exclusive
    <ng-container
      *ngIf="
        currentUser?.v1_plan?.includes('Perpetual') ||
          currentUser?.v1_plan?.includes('Yearly');
        else monthsOff
      "
    >
      50% off on {{ currentUser?.v1_plan }}
    </ng-container>
    <ng-template #monthsOff>
      2 months off on {{ currentUser?.v1_plan }}
    </ng-template>
    plan. When you make the switch to ADD 2.0 today, we'll take care of
    canceling your existing ADD 1.0 subscription so everything goes smooth.
    Ready to level up?
    <a (click)="upgrade()">Click here</a> to renew your plan in the new system!
  </div>
  <button class="close-button" (click)="hideActivationBanner()">
    <img src="assets/icons/close.svg" />
  </button>
</div>
