import {Injectable} from '@angular/core';
import {NotificationsService} from 'angular2-notifications';

const nl2br = (value) => (value || '').replace(/(?:\r\n|\r|\n)/g, '<br />');

@Injectable({providedIn: 'root'})
export class NotificationToasterService {

  constructor(private _service: NotificationsService) {

  }

  private sanitize(input) {
    // return input.replace(/[\u00A0-\u9999<>\&]/gim, function(i) {
    //     return '&#' + i.charCodeAt(0) + ';';
    // });
    // return this.sanitizer.sanitize(SecurityContext.HTML, input);
    const div = document.createElement('div');
    div.innerText = input;
    return div.innerHTML;
  }

  private parseMessage(input) {
    return nl2br(this.sanitize(input));
  }


  info(msg: any, title?: any) {
    return this._service.info(title ? this.sanitize(title) : 'Info', this.parseMessage(msg));
  }

  success(msg: any, title?: any) {
    return this._service.success(title ? this.sanitize(title) : 'Success', this.parseMessage(msg));
  }

  warn(msg: any, title?: any) {
    return this._service.alert(title ? this.sanitize(title) : 'Warning', this.parseMessage(msg));
  }

  error(msg: any, title?: any) {
    return this._service.error(title ? this.sanitize(title) : 'Error', this.parseMessage(msg));
  }


}
