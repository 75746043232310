import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { UserDetailsResponse } from './user.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public user: UserDetailsResponse;

  constructor(private http: HttpClient, public dialog: MatDialog) {}

  getUser() {
    const currentUrl = window.location.href;

    if (currentUrl.includes('activate-email')) {
      return Promise.resolve(); // Skip initialization
    }
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiUrl}/users/me`).subscribe(
        (response: any) => {
          this.user = response;
          this.updateUserSessionTimeout();
          resolve(true);
        },
        (error) => {
          // @ts-ignore
          window.location = environment.authUrl;
          reject('Server error - cannot load layout...');
        }
      );
    });
  }

  public tryRefreshToken() {
    return new Promise<void>((resolve) => {
      this.refreshToken(() => {
        resolve();
      });
    });
  }

  resendActivationCode() {
    return this.http.post<any>(
      `${environment.apiUrl}/users/resend-activation-code`,
      {}
    );
  }

  activateEmail(code) {
    return this.http.post<any>(`${environment.apiUrl}/users/activate-email`, {
      code,
    });
  }

  logout() {
    this.http
      .post<any>(`${environment.apiUrl}/auth/logout`, {})
      .subscribe(() => {
        localStorage.setItem('isActivationBannerHidden', 'false');
        // @ts-ignore
        window.location = environment.authUrl.toString();
      });
    this.user = null;
  }

  wrongUser() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: new ConfirmDialogModel(
        'Different user',
        'You are logged in with a different user, you need to login with the correct one',
        true
      ),
    });

    dialogRef.afterClosed().subscribe((_) => {
      this.http
        .post<any>(`${environment.apiUrl}/auth/logout`, {})
        .subscribe(() => {
          // @ts-ignore
          window.location = environment.authUrl.toString();
        });
      this.user = null;
    });
  }

  get isPaidUser() {
    const packageName = this.user?.subscriptions?.[0]?.package?.name;
    return packageName !== 'Trial' && packageName !== 'Free';
  }

  private refreshToken(cb = () => null) {
    const user = this.user;
    // this.http.post<any>(`${environment.apiUrl}/auth/refresh-token`,null).subscribe(r => {
    //   this.currentUserSubject.next(r);
    //   cb();
    // }, error => {
    //   this.logout();
    // });
  }

  public openAdd() {
    this.http
      .get<any>(`${environment.apiUrl}/auth/single-use-token`)
      .subscribe({
        next: (r) => {
          const iframe = document.createElement('iframe');
          iframe.src = environment.desktop + '?token=' + r.token;
          iframe.name = 'frame';
          document.getElementById('addOpenTrigger').appendChild(iframe);
        },
        error: (e) => {},
      });
  }

  private updateUserSessionTimeout() {
    const user = this.user;
    setTimeout(() => {
      if (user) {
        this.refreshToken();
      }
      this.updateUserSessionTimeout();
    }, 1000 * 60 * 10);
  }
}
