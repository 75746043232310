import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivateEmailComponent } from './_shared/components/activate-email/activate-email.component';
import { MainLayoutComponent } from './_layouts/main-layout/main-layout.component';
import { LogoutComponent } from './_shared/components/logout/logout.component';

const routes: Routes = [
  {
    path: 'payment-status',
    loadChildren: () =>
      import('./payment-status/payment-status.module').then(
        (x) => x.PaymentStatusModule
      ),
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: 'activate-email', component: ActivateEmailComponent },
      {
        path: 'account',
        loadChildren: () =>
          import('./account/account.module').then((x) => x.AccountModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((x) => x.DashboardModule),
      },
      {
        path: 'preferences',
        loadChildren: () =>
          import('./preferences/preferences.module').then(
            (x) => x.PreferencesModule
          ),
      },
      {
        path: 'purchases',
        loadChildren: () =>
          import('./purchases/purchases.module').then((x) => x.PurchasesModule),
      },
      {
        path: 'subscription',
        loadChildren: () =>
          import('./subscription/subscription.module').then(
            (x) => x.SubscriptionModule
          ),
      },
      { path: 'logout', component: LogoutComponent },
      { path: 'payment/cancel', redirectTo: '/subscription/change-plan' },
      { path: '**', redirectTo: 'dashboard' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
