<div
  class="banner"
  *ngIf="
    currentUser?.subscriptions?.[0]?.canceledDate
  "
>
  <app-spinner *ngIf="loading"></app-spinner>
  <div>
    Your cancellation is scheduled and will take effect on
    {{currentUser?.subscriptions?.[0]?.endDate}}. You can easily revert this and
    keep your subscription active anytime before the end of the current billing
    cycle. <a (click)="revertCancellation()">Click here</a> to revert
    cancellation.
  </div>
</div>
