import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services';
import { NotificationToasterService } from '../../services/notification.service';

@Component({
  selector: 'app-managed-user-landing',
  templateUrl: './managed-user-landing.component.html',
  styleUrls: ['./managed-user-landing.component.scss'],
})
export class ManagedUserLandingComponent implements OnInit {
  isDownloading = false;

  constructor(
    public authenticationService: AuthenticationService,
    private toaster: NotificationToasterService
  ) {}

  get currentUser() {
    return this.authenticationService.user;
  }

  openAdd() {
    this.authenticationService.openAdd();
  }

  downloadApp() {
    this.isDownloading = true;
    const url = 'https://dl.add.app/';

    setTimeout(() => {
      this.isDownloading = false;
    }, 2000);

    window.location.href = url;
  }

  ngOnInit(): void {}
}
