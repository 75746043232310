<div class="email-activation-wrapper">
  <div class="container">
    <div class="alert-circle">
      <img height="200" src="../../assets/thin-tick-circle.png" alt="" />
    </div>
    <div>
      <h2>
        Welcome back, {{ currentUser.firstName }} {{ currentUser.lastName }}!
      </h2>
      <div>
        Your access is being managed by
        <span>
          {{ currentUser?.parentUser?.firstName }}
          {{ currentUser?.parentUser?.lastName }}
        </span>
        ADD plan.
      </div>
    </div>
    <div class="btn-wrapper">
      <button
        mat-raised-button
        color="accent"
        (click)="downloadApp()"
        [style.opacity]="isDownloading ? 0.5 : 1"
        style="height: 2.5rem"
      >
        <app-spinner *ngIf="isDownloading"></app-spinner>
        <div *ngIf="!isDownloading" class="download-btn">
          <img
            src="assets/buttons/download-underline.svg"
            class="download-icon icon"
          />
          Download ADD 2.0
        </div>
      </button>
      <button
        mat-raised-button
        style="background-color: rgba(255, 210, 129, 0.16)"
        (click)="openAdd()"
      >
        <img src="assets/icons/app-yellow.svg" /> &nbsp;<span
          style="color: rgba(255, 210, 129, 1)"
          >Launch ADD Desktop</span
        >
      </button>
    </div>
  </div>

  <app-helpdesk></app-helpdesk>
</div>
