import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services';
import { NotificationToasterService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-activate-email',
  templateUrl: './activate-email.component.html',
  styleUrls: ['./activate-email.component.scss'],
})
export class ActivateEmailComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  code = null;
  verified = 'INIT';

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private toaster: NotificationToasterService,
    private activatedRoute: ActivatedRoute
  ) {
    this.subs.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.code = params['code'];
        this.activateEmail();
      })
    );
  }

  get currentUser() {
    return this.authenticationService.user;
  }

  activateEmail() {
    this.subs.push(
      this.authenticationService.activateEmail(this.code).subscribe((r) => {
        if (r) {
          this.verified = 'SUCCESS';
          this.toaster.success('Email activated successfully');
          this.authenticationService.user.emailActivated = true;
          this.authenticationService.user.user_activated = 'Y';
        } else {
          this.verified = 'FAILED';
          this.toaster.error('Invalid code or email already activated');
        }
        // this.router.navigate(['/']).then(null);
      })
    );
  }

  login() {
    window.location.href = '/';
    // this.router.navigate(['/']).then(null);
  }

  ngOnInit(): void {
    // setTimeout(()=>{
    //   this.activateEmail()
    // })
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
