import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class BillingService {
  constructor(private http: HttpClient) {}

  getInvoices(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/subscriptions/my-invoices`
    );
  }

  manageBilling(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/subscriptions/manage-billing`
    );
  }
  sendCustomEmail(chargeId): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/subscriptions/send-email-invoice`,
      {
        chargeId,
      }
    );
  }
}
