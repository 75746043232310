import { Component, OnInit } from '@angular/core';
import { NotificationToasterService } from '../../services/notification.service';
// import { PlanService } from 'src/app/subscription/plan/plan.service';
import { AuthenticationService } from '../../services';
import { BillingService } from 'src/app/subscription/billing/billing.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-payment-failed-banner',
  templateUrl: './payment-failed-banner.component.html',
  styleUrls: ['./payment-failed-banner.component.scss'],
  providers: [BillingService],
})
export class PaymentFailedBannerComponent implements OnInit {
  constructor(
    private billingService: BillingService,
    public authenticationService: AuthenticationService,
    private toaster: NotificationToasterService
  ) {}

  get currentUser() {
    return this.authenticationService.user;
  }

  get isPaymentFailed() {
    return this.currentUser?.subscriptions?.[0]?.status === 'PAYMENT_FAILED';
  }

  hideActivationBanner() {}

  completePayment() {
    this.billingService
      .manageBilling()
      .pipe(first())
      .subscribe({
        next: (r) => {
          if (r.success) {
            window.location.href = r?.data;
          } else {
            this.toaster.error(r.message);
          }
        },
        error: (error) => {
          this.toaster.error(error);
        },
        complete: () => {},
      });
  }

  ngOnInit(): void {}
}
