import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class OverviewService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<any>(
      `${environment.apiUrl}/subscriptions/my-subscriptions`
    );
  }

  getPricingPlans(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/packages/pricing`);
  }

  getAllPackages(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/packages`);
  }

  getFeatures(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/features`);
  }

  updateSubscription(subId, payload): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/subscriptions/${subId}`,
      payload
    );
  }
  activateCode(code): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/payments/activate-by-code/${code}`,
      {}
    );
  }

  geSubscriptionDetails(subId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/subscriptions/${subId}`);
  }

  createSubscription(payload): Observable<any> {
    return this.http.post(`${environment.apiUrl}/subscriptions/new`, payload);
  }

  cancelSubscription(subscriptionId): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}/subscriptions/cancel-my-subscription/${subscriptionId}`,
      {
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  revertSubscription(subscriptionId): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/subscriptions/revert-my-subscription/${subscriptionId}`,
      {
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  createCheckoutSession(
    priceId,
    packageId,
    subscriptionId,
    appUrl
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/subscriptions/create-checkout-session`,
      {
        priceId,
        appUrl,
        packageId,
        subscriptionId,
      }
    );
  }
}
