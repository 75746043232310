import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  AuthenticationService,
  UserDetailsResponse,
  UserService,
} from './_shared/services';
import { posthog } from 'posthog-js';
import { environment } from '@environments/environment';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  subs: Subscription[] = [];
  currentUser: UserDetailsResponse;
  public options: any = {
    timeOut: 5000,
    lastOnBottom: true,
    clickToClose: true,
    maxLength: 0,
    maxStack: 7,
    showProgressBar: true,
    pauseOnHover: true,
    preventDuplicates: false,
    preventLastDuplicates: false,
    rtl: false,
    animate: 'scale',
    position: ['left', 'bottom'],
  };
  isManagedLogin = false;

  userId;
  me;

  constructor(
    private activatedRoute: ActivatedRoute,
    private user: UserService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.subs.push(
      this.activatedRoute.queryParamMap.subscribe((params) => {
        this.userId = params.get('userId');
        if (this.userId) {
          this.subs.push(
            this.user.getProfile().subscribe((r) => {
              this.me = r;
              if (this.userId && this.me.id && this.me.id != this.userId) {
                this.authenticationService.wrongUser();
              }
            })
          );
        } else {
          const currentUser = this.authenticationService.user;
          this.currentUser = currentUser;
          if (this.currentUser?.parentUserId) {
            this.isManagedLogin = true;
          }
          // Check if currentUser is available and its version is "V1.0"
          if (
            currentUser &&
            currentUser?.user_version === 'V1.0' &&
            currentUser?.emailActivated &&
            currentUser?.user_activated === 'Y' &&
            !this.authenticationService.isPaidUser &&
            window.location.pathname.includes('dashboard')
          ) {
            this.router.navigate(['/subscription/change-plan']);
          } else if (currentUser?.id && currentUser?.user_activated !== 'Y') {
            this.router.navigate(['/dashboard']);
          }
        }
      })
    );
  }

  ngOnInit() {
    if (environment.production && this.currentUser) {
      const user = this.currentUser;
      posthog.identify(user?.email, {
        email: user?.email,
        name: user?.firstName,
        id: user?.id,
      });
    }
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
