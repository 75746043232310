import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { catchError } from 'rxjs';
import { AuthenticationService } from 'src/app/_shared/services';
import { NotificationToasterService } from 'src/app/_shared/services/notification.service';
import { PlanService } from '../plan.service';
import { CommonModule } from '@angular/common';
import { StripeService } from 'ngx-stripe';

@Component({
  selector: 'app-purchase-tokens',
  templateUrl: 'purchase-tokens.component.html',
  styleUrls: ['./purchase-tokens.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  providers: [PlanService],
})
export class PurchaseTokenDialogComponent implements OnInit {
  tokens: any[] = [];
  loading: boolean = false;
  isBuyingToken: boolean = false;
  selectedToken: any = {};
  randToken = 'random';

  constructor(
    public authenticationService: AuthenticationService,
    private toaster: NotificationToasterService,
    private planService: PlanService,
    public dialogRef: MatDialogRef<PurchaseTokenDialogComponent>
  ) {}

  get currentUser() {
    return this.authenticationService.user;
  }

  ngOnInit() {
    this.loadTokensPackage();
  }

  selectTokenPackage(element) {
    this.selectedToken = element;
  }

  loadTokensPackage() {
    this.loading = true;
    this.planService.getTokens().subscribe((r) => {
      this.tokens = r;
      this.loading = false;
    });
  }

  buyTokenPackage() {
    let priceId = this?.selectedToken?.stripePriceId;
    if (priceId) {
      const payload = { priceId, isRecurrent: false };
      this.isBuyingToken = true;
      this.planService
        .buyTokens(payload)
        .pipe(
          catchError((error: any) => {
            this.toaster.error(
              'An error occurred while processing your request.'
            );
            this.isBuyingToken = false;
            return null;
          })
        )
        .subscribe(
          (r) => {
            this.isBuyingToken = false;
            if (r?.success) {
              this.selectedToken = {};
              this.dialogRef.close();
              window.location.href = r.data;
            } else {
              this.toaster.error(r?.error);
            }
            this.isBuyingToken = false;
          },
          (error) => {
            this.toaster.error(error);
          }
        );
    }
  }
}
