import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, tap, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthenticationService} from '../services';
import {environment} from '@environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(async r => {


    }), catchError(err => {
      if (err.status === 401) {
        // @ts-ignore
        window.location = environment.authUrl.toString();
      }
      if (err.status === 403) {
        //Forbidden
      }
      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }
}
