<div class="redirection-card-wrapper">
  <div class="redirection-card">
    <div class="icon">
      <img src="../../assets/icons/manual.svg" alt="" />
    </div>
    <div class="content">
      <h3>User Manual</h3>
      <p>Access the Knowledge Base for in depth documentation.</p>
      <a href="https://add.app/knowledge-base/" class="btn" target="_blank">Read More
        <img src="../../assets/icons/arrow-right.svg" alt="" />
      </a>
    </div>
  </div>
  <div class="redirection-card">
    <div class="icon">
      <img src="../../assets/icons/support.svg" alt="" />
    </div>
    <div class="content">
      <h3>Contact Support</h3>
      <p>Access the Help Desk for product support and ticket submission.</p>
      <a href="https://addhelp.freshdesk.com/support/tickets/new" target="_blank" class="btn">Read More
        <img src="../../assets/icons/arrow-right.svg" alt="" />
      </a>
    </div>
  </div>
  <div class="redirection-card">
    <div class="icon">
      <img src="../../assets/icons/slack.svg" alt="" />
    </div>
    <div class="content">
      <h3>Slack Community</h3>
      <p>Share and connect with others in a growing community!</p>
      <a href="https://audiodesigndeskusers.slack.com/join/shared_invite/zt-oak1a88n-5YO~F~Hor01FmJq~mKic6w?utm_medium=email&_hsenc=p2ANqtz-8eCUnfBIJZ8Hr2Q0kk6-UDjP3jVvgvsEFED7rA6he04PnFoQz6SRs00sTllFH1FI75RSSV8-yC-p2lZhJboiYY_6FLIA&_hsmi=204386736&utm_content=204386736&utm_source=hs_automation"
        target="_blank" class="btn">Read More
        <img src="../../assets/icons/arrow-right.svg" alt="" />
      </a>
    </div>
  </div>
</div>