export const environment = {
  production: false,
  apiUrl: 'https://dev-api.add.app',
  authUrl: 'https://dev-auth.add.app?client=account',
  soundsApp: 'https://dev-sounds.add.app',
  communityApp: 'https://add.app',
  desktop: 'audiodesigndesk://login',
  stripePk:
    'pk_test_51JhcOBLGkj1zBGEji3NA3U9eeU2koPOT71PaCPNS5QuSm63jcfFXzwfg0lVY2elGWJy3goMHCNTsSdJAeymtJMLj00eAyB2ess',
  postHogKey: '',
  postHogHost: '',
};

import 'zone.js/dist/zone-error'; // Included with Angular CLI.
