import { Component, OnInit } from '@angular/core';
import { NotificationToasterService } from '../../services/notification.service';
// import { PlanService } from 'src/app/subscription/plan/plan.service';
import { AuthenticationService } from '../../services';
import { PlanService } from 'src/app/subscription/plan/plan.service';

@Component({
  selector: 'app-account-activation-banner',
  templateUrl: './account-activation-banner.component.html',
  styleUrls: ['./account-activation-banner.component.scss'],
  providers: [PlanService],
})
export class AccountActivationBannerComponent implements OnInit {
  isActivationBannerHidden: boolean;
  constructor(
    public planService: PlanService,
    public authenticationService: AuthenticationService,
    private toaster: NotificationToasterService
  ) {
    this.isActivationBannerHidden =
      JSON.parse(localStorage.getItem('isActivationBannerHidden')) || false;
  }
  get currentUser() {
    return this.authenticationService.user;
  }

  hideActivationBanner() {
    localStorage.setItem('isActivationBannerHidden', 'true');
    this.isActivationBannerHidden = true;
  }

  upgrade() {
    this.planService.upgradeVersion().subscribe((r) => {
      if (r?.url) {
        window.location.href = r?.url;
      } else {
        this.toaster.error('Something went wrong');
      }
    });
  }

  ngOnInit(): void {}
}
