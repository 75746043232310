import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  providers: [],
})
export class LogoutComponent implements OnInit {
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .post<any>(`${environment.apiUrl}/auth/logout`, {})
      .subscribe(() => {
        // @ts-ignore
        window.location = environment.authUrl.toString();
      });
  }
}
