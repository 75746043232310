<button class="close-button" mat-dialog-close>
  <img height="18" width="18" src="../../assets/icons/close.svg" alt="" />
</button>
<mat-dialog-content class="mat-typography">
  <h2 style="margin: 0px" mat-dialog-title>Get more Tokens</h2>
  <small style="color: #aaa"> Select a token package to purchase. </small>
  <div *ngIf="loading">loading...</div>
  <div class="token-wrapper">
    <div
      *ngFor="let token of tokens"
      (click)="selectTokenPackage(token)"
      [ngClass]="{
        'selected-token': selectedToken?.id === token.id,
        'token-package': selectedToken?.id !== token.id
      }"
    >
      <div>{{ token.tokens }} tokens</div>
      <div>${{ token.price }}</div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    style="width: 100%"
    mat-raised-button
    color="accent"
    cdkFocusInitial
    (click)="buyTokenPackage()"
    [disabled]="!selectedToken?.id"
  >
    {{ isBuyingToken ? "Processing..." : "Buy Now" }}
  </button>
</mat-dialog-actions>
